import { Grid, Stack, styled, Typography } from '@mui/material';
import Link from 'next/link';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { TritonLogo } from 'components/TritonLogo';
import { theme } from 'styles/mui-theme';
import { TritonButton } from 'styles/styles';

export const Header = ({ hideLinks }: { hideLinks?: boolean }): React.ReactElement => {
    return (
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs="auto" md>
                <TritonLogo />
            </Grid>
            {!hideLinks ? (
                <Grid item xs={12} md="auto" alignSelf="center">
                    <NavLinks />
                </Grid>
            ) : null}
        </Grid>
    );
};

export const NavLinks = () => {
    const intl = useIntl();

    const navLinks: { label: string; href: string; targetBlank?: boolean }[] = [
        {
            label: intl.formatMessage({
                defaultMessage: 'Rankers',
                description: 'Navigation link for rankers',
            }),
            href: '/#rankers',
        },
        {
            label: intl.formatMessage({
                defaultMessage: 'Resources',
                description: 'Navigation link for resources',
            }),
            href: '/#resources',
        },
        {
            label: intl.formatMessage({
                defaultMessage: 'Contact us',
                description: 'Navigation link for contact us',
            }),
            href: contactEmail,
            targetBlank: true,
        },
    ];

    return (
        <Stack direction="row" spacing={0.5} justifyContent="center" sx={{ paddingX: '2px' }}>
            {navLinks.map((link) => (
                <HeaderLink
                    href={link.href}
                    key={link.href}
                    target={link.targetBlank ? '_blank' : ''}
                    LinkComponent={Link}
                >
                    <Typography>{link.label}</Typography>
                </HeaderLink>
            ))}
        </Stack>
    );
};

export const contactEmail = 'mailto:solutions@tritondigital.com?subject=Podcast%20Reports';

const HeaderLink = styled(TritonButton)(() => ({
    '&.MuiButton-root': [
        {
            background: 'transparent',
            borderColor: 'transparent',
            color: theme.tritonColors.darkBlue,
            '&:hover': {
                background: theme.tritonColors.darkBlue,
                color: 'white',
            },
            padding: '10px 14px',
            [theme.breakpoints.down(640)]: {
                fontSize: 14,
                padding: '10px 6px',
            },
            [theme.breakpoints.down(430)]: {
                fontSize: 12,
            },
            textAlign: 'center',
        },
    ],
}));
