import { css } from '@emotion/react';
import { Facebook, LinkedIn, Twitter, YouTube, type SvgIconComponent } from '@mui/icons-material';
import { Container, Divider, Grid, lighten, Stack, styled, Typography } from '@mui/material';
import { type StaticImageData } from 'next/image';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useOneTrustSettingsLink } from './OneTrustCookieBanner';
import { LocaleChanger } from 'components/LocaleChanger';
import ACPMLogo from 'images/certification-logos/ACPM_LOG-Q.png';
import IabAustraliaLogo from 'images/certification-logos/iab-aus.png';
import IabCanadaLogo from 'images/certification-logos/iab-ca.png';
import IabEuropeLogo from 'images/certification-logos/IAB-Europe-LOGO.png';
import IabTechLabSeal from 'images/certification-logos/iab-tech-lab-seal_NEW_footer.png';
import IabUKLogo from 'images/certification-logos/iab-uk.png';
import IabUsLogo from 'images/certification-logos/iab-us.png';
import IabFranceLogo from 'images/certification-logos/IABFrancelogo.png';
import GesteLogo from 'images/certification-logos/Logo-GESTE1000px.png';
import MDALogo from 'images/certification-logos/mda-logo.png';
import RABLogo from 'images/certification-logos/rab-logo-3.png';
import TritonLogoWhite from 'images/triton-logo-white.svg';
import { theme } from 'styles/mui-theme';
import { buttonReset } from 'styles/styles';

export const TritonFooter = (): React.ReactElement => {
    const intl = useIntl();
    const oneTrustSettingsLink = useOneTrustSettingsLink();

    const legalLinks: { label: string; href: string }[] = [
        {
            label: intl.formatMessage({
                defaultMessage: 'Terms of Use',
                description: 'Footer legal link',
            }),
            href: 'https://www.tritondigital.com/terms-of-use',
        },
        {
            label: intl.formatMessage({
                defaultMessage: 'Privacy Policies',
                description: 'Footer legal link',
            }),
            href: 'https://www.tritondigital.com/privacy-policies',
        },
        {
            label: intl.formatMessage({
                defaultMessage: 'Acceptable Use Policy',
                description: 'Footer legal link',
            }),
            href: 'https://www.tritondigital.com/acceptable-use-policy',
        },
        {
            label: intl.formatMessage({
                defaultMessage: 'Copyright Policy',
                description: 'Footer legal link',
            }),
            href: 'https://www.tritondigital.com/copyright-policy',
        },
    ];

    const links: Record<string, { label: string; href: string }[]> = {
        [intl.formatMessage({
            defaultMessage: 'About',
            description: 'Footer links heading',
        })]: [
            {
                label: intl.formatMessage({
                    defaultMessage: 'Company',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/about/company',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Partners',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/about/partners',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Newsroom',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/about/newsroom',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Careers',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/about/careers',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Locations',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/about/locations',
            },
        ],
        [intl.formatMessage({
            defaultMessage: 'Solutions',
            description: 'Footer links heading',
        })]: [
            {
                label: intl.formatMessage({
                    defaultMessage: 'Audio Streaming',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/solutions/audio-streaming',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Podcasting',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/solutions/podcasting',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Audio Advertising',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/solutions/audio-advertising',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Audience Measurement',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/solutions/audience-measurement',
            },
        ],
        [intl.formatMessage({
            defaultMessage: 'Resources',
            description: 'Footer links heading',
        })]: [
            {
                label: intl.formatMessage({
                    defaultMessage: 'Podcast Reports',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/resources/podcast-reports',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Streaming Metrics Rankers',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/resources/monthly-rankers/rankers-archive',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Preferred Partners',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/about/partners',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Programmatic for Buyers',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/resources/programmatic-for-buyers',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Programmatic for Publishers',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/resources/programmatic-for-publishers',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Downloads',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/downloads',
            },
        ],
        [intl.formatMessage({
            defaultMessage: 'Connect',
            description: 'Footer links heading',
        })]: [
            {
                label: intl.formatMessage({
                    defaultMessage: 'Contact Us',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/connect/contact-us',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Events Calendar',
                    description: 'Footer link',
                }),
                href: 'https://www.tritondigital.com/connect/events-calendar',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Support',
                    description: 'Footer link',
                }),
                href: 'https://tritondigitalcommunity.force.com/s/',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Blog',
                    description: 'Footer link',
                }),
                href: 'https://blog.tritondigital.com/',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'System Status',
                    description: 'Footer link',
                }),
                href: 'https://tritonstatus.statuspage.io/',
            },
        ],
    };

    return (
        <div
            css={{
                backgroundColor: '#ebeef1',
                color: foregroundColor,
                padding: '3em 0',
            }}
        >
            <Container>
                <Grid container spacing={1}>
                    {certificationLogos.map((logo) => (
                        <Grid item key={logo.label} alignSelf="center" textAlign="center" xs={3} md={2} lg>
                            <img
                                src={logo.image.src}
                                key={logo.label}
                                alt={logo.label}
                                title={logo.label}
                                css={{
                                    width: 70,
                                    [theme.breakpoints.down('sm')]: {
                                        width: 50,
                                    },
                                    height: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={2} my={3}>
                    {Object.entries(links).map(([heading, links]) => (
                        <Grid item xs={6} sm key={heading}>
                            <Typography
                                variant="h6"
                                css={{
                                    fontSize: 15,
                                    marginBottom: '0.5em',
                                    color: theme.tritonColors.darkBlue,
                                    textTransform: 'uppercase',
                                }}
                            >
                                {heading}
                            </Typography>
                            <ul css={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                {links.map((link) => (
                                    <li
                                        key={link.label}
                                        css={{
                                            margin: '0.3em 0',
                                        }}
                                    >
                                        <Typography>
                                            <FooterA href={link.href}>{link.label}</FooterA>
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Stack spacing={2}>
                    <Grid item xs={12} container justifyContent="center">
                        {legalLinks.map((legal, index) => (
                            <React.Fragment key={legal.href}>
                                {index > 0 ? (
                                    <Divider orientation="vertical" flexItem css={{ marginLeft: 6, marginRight: 6 }} />
                                ) : null}
                                <FooterA href={legal.href}>
                                    <Typography>{legal.label}</Typography>
                                </FooterA>
                            </React.Fragment>
                        ))}
                        {oneTrustSettingsLink ? (
                            <>
                                <Divider orientation="vertical" flexItem css={{ marginLeft: 6, marginRight: 6 }} />
                                <button
                                    type="button"
                                    css={[buttonReset, footerLinkStyle]}
                                    onClick={oneTrustSettingsLink.onClick}
                                >
                                    {oneTrustSettingsLink.label}
                                </button>
                            </>
                        ) : null}
                    </Grid>
                    <Grid container item xs={12} rowSpacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            container
                            alignContent="center"
                            justifyContent={{ xs: 'center', md: 'left' }}
                        >
                            <Stack direction="row" spacing={2}>
                                {socialLinks.map((social) => (
                                    <FooterA
                                        href={social.href}
                                        key={social.href}
                                        css={{
                                            alignSelf: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <social.icon
                                            css={{
                                                width: 32,
                                                height: 32,
                                            }}
                                        />
                                    </FooterA>
                                ))}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} container alignContent="center" justifyContent={{ xs: 'center' }}>
                            <Grid item>
                                <LocaleChanger />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} container justifyContent={{ xs: 'center', md: 'right' }}>
                            <Grid item>
                                <TritonLogoWhite
                                    width={140}
                                    height={undefined}
                                    css={{
                                        color: foregroundColor,
                                        '& path': { fill: 'currentColor' },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </div>
    );
};

const certificationLogos: { label: string; image: StaticImageData }[] = [
    { label: 'IAB', image: IabUsLogo },
    { label: 'IAB Australia', image: IabAustraliaLogo },
    { label: 'IAB Canada', image: IabCanadaLogo },
    { label: 'IAB France', image: IabFranceLogo },
    { label: 'IAB UK', image: IabUKLogo },
    { label: 'IAB Europe', image: IabEuropeLogo },
    { label: 'IAB Tech Lab Standards Compliant Verified', image: IabTechLabSeal },
    { label: 'ACPM', image: ACPMLogo },
    { label: 'Geste', image: GesteLogo },
    { label: 'Malaysian Digital Association', image: MDALogo },
    { label: 'Radio Advertising Bureau', image: RABLogo },
];

const socialLinks: { icon: SvgIconComponent; href: string }[] = [
    { icon: Twitter, href: 'https://twitter.com/TritonDigital' },
    { icon: LinkedIn, href: 'https://www.linkedin.com/company/triton-digital' },
    { icon: Facebook, href: 'https://www.facebook.com/tritondigital.connect/' },
    {
        icon: YouTube,
        href: 'https://www.youtube.com/channel/UCF2qdkonP6OiEhvCoQkLkxA',
    },
];

const foregroundColor = lighten(theme.tritonColors.darkBlue, 0.4);

export const footerLinkStyle = css({
    color: foregroundColor,
    '&:hover': {
        textDecoration: 'underline',
        color: theme.tritonColors.darkBlue,
    },
});

const FooterA = styled('a')(footerLinkStyle);
