import { Box, Grid, Typography } from '@mui/material';
import TritonLogoColorUrl from 'images/triton-logo-color.svg?url';
import { theme } from 'styles/mui-theme';

export const TritonLogo = ({ className }: { className?: string }) => {
    return (
        <img
            src={TritonLogoColorUrl}
            alt="Triton Logo"
            css={{
                width: 200,
            }}
            className={className}
        />
    );
};

export const TritonLogoSmallWithLabel = ({ className, label }: { className?: string | undefined; label: string }) => (
    <Grid container spacing={1} alignItems="center" className={className}>
        <Grid item xs="auto" sx={{ marginBottom: -0.5 }}>
            <TritonLogo
                css={{
                    width: 100,
                }}
            />
        </Grid>
        <Grid item xs="auto">
            <Box
                sx={{
                    width: '1px',
                    height: 18,
                    background: theme.tritonColors.darkBlue,
                    opacity: 0.2,
                }}
            />
        </Grid>
        <Grid item xs="auto">
            <Typography
                variant="h1"
                sx={{
                    fontSize: 18,
                    color: theme.tritonColors.darkBlue,
                }}
            >
                {label}
            </Typography>
        </Grid>
    </Grid>
);
