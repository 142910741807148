import LanguageIcon from '@mui/icons-material/Language';
import { MenuItem, type SelectChangeEvent } from '@mui/material';
import { useLocalizationContext } from 'components/LocalizationProvider';
import { type Locale, allLocales, getLocaleName } from 'models/locale';
import { ReportSelectOutlined } from 'styles/styles';

export const LocaleChanger = () => {
    const { locale, setLocale } = useLocalizationContext();

    const handleChange = (event: SelectChangeEvent) => {
        setLocale(event.target.value as Locale);
    };

    return (
        <ReportSelectOutlined
            value={locale}
            css={{
                '&.MuiFilledInput-root': {
                    color: 'currentcolor',
                    width: 300,
                    '& .MuiSelect-icon': { color: 'currentcolor' },
                },
            }}
            renderValue={(value: Locale) => (
                <>
                    <span
                        css={{
                            marginRight: 5,
                            lineHeight: 1,
                            verticalAlign: 'middle',
                        }}
                    >
                        <LanguageIcon fontSize="small" />
                    </span>
                    {getLocaleName[value]}
                </>
            )}
            onChange={handleChange}
        >
            {allLocales.map((locale) => (
                <MenuItem key={locale} value={locale}>
                    {getLocaleName[locale]}
                </MenuItem>
            ))}
        </ReportSelectOutlined>
    );
};
